body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Table Container */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

/* Header Styling */
.styled-table thead tr {
  /* background-color: #009879; */
  color: #ffffff;
  text-align: left;
}

/* Cell Styling */
.styled-table th,
.styled-table td {
  padding: 9.5px 10px;
  border: 1px solid #dddddd;
}

/* Row Striping */
/* .styled-table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
} */

/* Hover Effect */
.styled-table tbody tr:hover {
  background-color: #ddd;
}
